<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="auth-box">
      <h3 class="title mb10">{{ $t(129) }}</h3>
      <div class="tip mb20">{{ $t(130) }}</div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form">
        <div>
          <div class="item">
            <div class="label mb5">{{ $t(165) }}</div>
            <el-form-item>
              <el-select v-model="form.country" class="code-select" filterable popper-class="bfff">
                <el-option
                  v-for="item in countryList"
                  :key="item.countryCode"
                  :label="item.englishName"
                  filterable
                  :value="item.countryCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="item">
          <div class="label mb5">{{ $t(166) }}</div>
          <el-form-item
            prop="firstName">
              <el-input v-model="form.firstName">
            </el-input>
          </el-form-item>
        </div>
        <div class="item">
          <div class="label mb5">{{ $t(167) }}</div>
          <el-form-item
            prop="lastName">
              <el-input v-model="form.lastName">
            </el-input>
          </el-form-item>
        </div>
        <div class="item">
          <div class="label mb5">{{ $t(168) }}</div>
          <el-form-item
            prop="certificateKind">
              <el-select v-model="form.certificateKind">
                <el-option value="ID_CARD" :label="$t(179)"></el-option>
                <el-option value="PASSPORT" :label="$t(180)"></el-option>
              </el-select>
          </el-form-item>
        </div>
        <div class="item">
          <div class="label mb5">{{ $t(169) }}</div>
          <el-form-item
            prop="certificateNo">
              <el-input v-model="form.certificateNo">
              </el-input>
          </el-form-item>
        </div>
        <div class="pt20 pb10"><el-button type="primary" @click="nextStep">{{ $t(181) }}</el-button></div>
        <el-button type="text" @click="$router.go(-1)" class="el-icon-back fs16"></el-button>
      </el-form>
      <div class="corpyrights">© 2017 - 2025 {{ configObj.appName }} All rights reserved</div>
    </div>
  </div>
</template>

<script>
import loginMixins from '@/mixins/login.js'
import SimpleHeader from '@/components/SimpleHeader'
import { authenticate } from '@/api'
import utils from '@/utils/misc'
export default {
  name: 'AuthVerify',
  components: { SimpleHeader },
  mixins: [loginMixins],
  data() {
    return {
      form: {
        country: 'USA',
        firstName: '',
        lastName: '',
        certificateKind: 'ID_CARD',
        certificateNo: '',
      }
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
        firstName: [{ validator: (rule, value, callback) => {
          if (value === '') {
            return callback(new Error(this.$t(171)))
          }
          callback()
        } }],
        lastName: [{ validator: (rule, value, callback) => {
          if (value === '') {
            return callback(new Error(this.$t(172)))
          }
          callback()
        } }],
        certificateNo: [{ validator: (rule, value, callback) => {
          if (value === '') {
            return callback(new Error(this.$t(173)))
          }
          callback()
        } }]
      }
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  beforeMount() {
    if (this.countryList.length === 0) {
      this.$store.dispatch('getCountries')
    }
  },
  methods: {
    nextStep() {
      this.$refs.form.validate(valid => {
        if (valid) {
          utils.setLs('authStepOne', this.form)
          this.$router.push({name: 'AuthStepTwo'})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .auth-box{
    color: #1E2329;
    width: 400px;
    margin: 10vh auto;
    .title{
      font-size: 32px;
      font-weight: 600;
    }
    .tab{
      margin: 40px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
  }
  .corpyrights{
    position: fixed;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
  }
</style>
